import { createRoot } from 'react-dom/client';
import React from 'react';
import NavBar from '@components/navbar';
import Header from '@components/header';
import Footer from '@components/footer';
import Feature from '@components/feature';
import FeatureSection from '@components/featuresection';

const root = createRoot(document.getElementById('app') as HTMLElement);

root.render(
  <React.StrictMode>
    <div className="bg-hero-background8 bg-cover bg-fixed bg-center bg-no-repeat">
      <NavBar current="/" />
      <Header>
        <Header.Super>Discoveries</Header.Super>
        <Header.Body>A PvE guild on Kazzak EU</Header.Body>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href="#main"
            className="rounded-sm bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-black hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-400"
          >
            Get started
          </a>
          <a
            href="/recruitment.html"
            className="text-sm font-semibold leading-6 text-black"
          >
            Recruitment <span aria-hidden="true">→</span>
          </a>
        </div>
      </Header>
      <div className="bg-transparent" id="main">
        <FeatureSection>
          <FeatureSection.Header>
            <FeatureSection.Title>Discoveries</FeatureSection.Title>
            <FeatureSection.SubTitle>What We Do</FeatureSection.SubTitle>
            <FeatureSection.Description>
              <p>
                We are an ambitious group of players that value efficiency.
                <br />
                We set high goals we don't think we'll be able to reach and so
                far we've met every single one ahead of schedule. <br />
                We always strive for improvement. <br />
                <br />
                In short we are a progress oriented guild both in and outside of
                raids.
              </p>
            </FeatureSection.Description>
          </FeatureSection.Header>
          <FeatureSection.Body>
            <Feature>
              <Feature.Header>About Us</Feature.Header>
              <Feature.Body>
                <Feature.Description>
                  Learn about our guild and what you can expect from us
                </Feature.Description>
                <Feature.Link href="/about.html" />
              </Feature.Body>
            </Feature>
            <Feature>
              <Feature.Header>Recruitment</Feature.Header>
              <Feature.Body>
                <Feature.Description>
                  See our current recruitment status and find out how to apply
                </Feature.Description>
                <Feature.Link href="/recruitment.html" />
              </Feature.Body>
            </Feature>
            <Feature>
              <Feature.Header>News</Feature.Header>
              <Feature.Body>
                <Feature.Description>
                  Read the latest updates from our guild's adventures
                </Feature.Description>
                <Feature.Link href="/blog.html" />
              </Feature.Body>
            </Feature>
          </FeatureSection.Body>
        </FeatureSection>
      </div>
      <Footer current="/" />
    </div>
  </React.StrictMode>,
);
