import { PropsWithChildren } from 'react';

const FeatureHeader = (props: PropsWithChildren) => {
  return (
    <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
      {props.children}
    </dt>
  );
};
const FeatureBody = (props: PropsWithChildren) => {
  return (
    <dd className="my-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
      {props.children}
    </dd>
  );
};
const FeatureDescription = (props: PropsWithChildren) => {
  return <p className="flex-auto">{props.children}</p>;
};
const FeatureLink = (props: { href: string }) => {
  return (
    <a
      href={props.href}
      className="text-sm font-semibold leading-6 text-blue-500"
    >
      Learn more <span aria-hidden="true">→</span>
    </a>
  );
};
const Feature = (props: PropsWithChildren) => {
  return <div className="flex flex-col ring-0 ring-gray-900 bg-gray-100 rounded-xl p-3 shadow-md shadow-gray-400">{props.children}</div>;
};
Feature.Header = FeatureHeader;
Feature.Body = FeatureBody;
Feature.Description = FeatureDescription;
Feature.Link = FeatureLink;
export default Feature;
